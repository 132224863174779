import feature1 from '../../common/assets/feature1.png';
import feature2 from '../../common/assets/feature2.png';
import feature3 from '../../common/assets/feature3.png';

import service1 from '../../common/assets/service1.png';
import service2 from '../../common/assets/service2.png';
import service3 from '../../common/assets/service3.png';
import service4 from '../../common/assets/service4.png';
import service5 from '../../common/assets/service5.png';
import service6 from '../../common/assets/service6.png';

import stats1 from '../../common/assets/stats1.png';
import stats2 from '../../common/assets/stats2.png';
import stats3 from '../../common/assets/stats3.png';
import stats4 from '../../common/assets/stats4.png';

import person1 from '../../common/assets/image1.jpg';
import person2 from '../../common/assets/image2.jpg';
import person3 from '../../common/assets/image3.png';
import person4 from '../../common/assets/image4.png';


export const data = {
    features: [{
            id: 1,
            icon: feature1,
            title: 'Analytics Report',
            desc: `We’re driven beyond just finishing the projects. We want to find smart solutions.`,
        },
        {
            id: 2,
            icon: feature2,
            title: 'User Customization',
            desc: `We’re driven beyond just finishing the projects. We want to find smart solutions.`,
        },
        {
            id: 3,
            icon: feature3,
            title: 'Help & Support',
            desc: `We’re driven beyond just finishing the projects. We want to find smart solutions.`,
        },
    ],
};

export const howTos = [{
        id: 1,
        icon: feature1,
        title: ' Insightful Skill Gap Analysis',
        text: ` Understand your skill gaps in your current industry. Identify the skill gap score to help you achieve your career goals.`,
        linkLabel: 'Start Earning',
        link: '#',
    },
    {
        id: 2,
        icon: feature2,
        title: ' Transition to New Industries',
        text: ` Thinking about switching industries? Our skill gap calculator reveals what skills you need to make a successful transition.`,
        linkLabel: 'Sign up your store',
        link: '#',
    },
    {
        id: 3,
        icon: feature3,
        title: ' Achieve Higher Salaries',
        text: ` Aspiring for a better salary? Learn what skills are crucial for career advancement and maximizing your potential.`,
        linkLabel: 'Get the app',
        link: '#',
    },
];

export const Service = [{
        id: 1,
        icon: service1,
        title: 'Hard and Soft Skills Breakdown',
        description: ' Identify the skills you need to excel in your current field and understand where you\'re falling short.',
    },
    {
        id: 2,
        icon: service2,
        title: 'Enhancement Tips',
        description: ' Receive a personalized pathway with actionable steps to enhance your skills and advance your career.',
    },
    {
        id: 3,
        icon: service3,
        title: ' Quick Assessment',
        description: ' Complete the skill gap calculator in just 2 minutes and get immediate insights.',
    },
    {
        id: 4,
        icon: service4,
        title: ' Free Resources',
        description: 'Gain access to a wealth of free resources designed to help you upskill and close your skill gaps.',
    },
    {
        id: 5,
        icon: service5,
        title: ' Expert Session',
        description: ' Enjoy a free consultation with industry experts who can guide you on your path to career success.',
    },
    {
        id: 6,
        icon: service6,
        title: ' Career Transition Gap',
        description: ' Transition smoothly into a new industry and achieve your career goals with the right skills.',
    },
];

export const stats = [{
        id: 1,
        icon: stats1,
        title: '350,000+',
        text: `Career Transformed`,
        linkLabel: 'Start Earning',
        link: '#',
    },
    {
        id: 2,
        icon: stats2,
        title: '2000+',
        text: <>IIM <br/>Alums</>,
        linkLabel: 'Sign up your store',
        link: '#',
    },
    {
        id: 3,
        icon: stats3,
        title: '1000+',
        text: `Corporate Association`,
        linkLabel: 'Get the app',
        link: '#',
    },
    {
        id: 4,
        icon: stats4,
        title: '256%',
        text: `Average Salary Hike Oppotunities`,
        linkLabel: 'Start Earning',
        link: '#',
    },
];

//export const USERTESTIMONIALS = {
//    testimonials: [{
//            id: 1,
//            photo: person1,
//            rating: 4,
//            name: 'Mehak Hundal',
//            text: `I transitioned from the postal dept to corporate banking with IIM Nagpur's Fintech course. Jaro's expert consultancy helped me secure a 40% salary hike`,
//        },
//        {
//            id: 2,
//            photo: person2,
//            rating: 3,
//            name: 'Satyajit Sutradhar',
//            text: `Jaro Education's Digital Marketing expertise at IIM Nagpur helped me secure a promotion and hike with excellent profile-building and job recommendation services. Highly recommended.Jaro Education's Digital Marketing expertise at IIM Nagpur helped me secure a promotion and hike with excellent profile-building and job recommendation services. Highly recommended.`,
//        },
//        {
//            id: 3,
//            photo: person3,
//            rating: 5,
//            name: 'Snover Sanduja',
//            text: `In 2022, I switched from pursuing a PhD at IIT Delhi to a corporate job. An ad for Jaro Education's Brand Management course from IIT Delhi caught my eye, and I enrolled. Six months later, I have valuable memories and learnings. Grateful for this enriching experience.`,
//        },
//        {
//            id: 4,
//            photo: person4,
//            rating: 5,
//            name: 'Riya Gulati',
//            text: `Jaro Education’s faculty was incredibly patient and understanding. They recommended the right courses and addressed all our doubts. The support team also ensured a smooth process. Now, I'm happily completing my Online MBA.`,
//        },
//    ],
//};

export const USERTESTIMONIALS = [{
        id: 1,
        photo: person1,
        rating: 4,
        name: 'Mehak Hundal',
        text: `I transitioned from the postal dept to corporate banking with IIM Nagpur's Fintech course. Jaro's expert consultancy helped me secure a 40% salary hike`,
    },
    {
        id: 2,
        photo: person2,
        rating: 3,
        name: 'Satyajit Sutradhar',
        text: `Jaro Education's Digital Marketing expertise at IIM Nagpur helped me secure a promotion and hike with excellent profile-building and job recommendation services. Highly recommended.`,
    },
    {
        id: 3,
        photo: person3,
        rating: 5,
        name: 'Snover Sanduja',
        text: `In 2022, I switched from pursuing a PhD at IIT Delhi to a corporate job. An ad for Jaro Education's Brand Management course from IIT Delhi caught my eye, and I enrolled. Six months later, I have valuable memories and learnings. Grateful for this enriching experience.`,
    },
    {
        id: 4,
        photo: person4,
        rating: 5,
        name: 'Riya Gulati',
        text: `Jaro Education’s faculty was incredibly patient and understanding. They recommended the right courses and addressed all our doubts. The support team also ensured a smooth process. Now, I'm happily completing my Online MBA.`,
    },
]