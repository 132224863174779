import React, { Fragment, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/aiexpertsedge.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Banner from '../components/Banner';
import Features from '../components/Features';
import PartnerSection from '../components/Partner';
import ServiceSection from '../components/ServiceSection';
import Stats from '../components/Stats';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import Disclaimer from '../components/Disclaimer';
import { Helmet } from 'react-helmet';
import { useWindowSize } from 'react-use';
import StickyButton from '../components/StickyButton';
import ReactGA from 'react-ga4';  // Import react-ga4

const AiExpertsEdgeLandingPage = () => {

  const { width, height } = useWindowSize();

 useEffect(() => {
    ReactGA.send('pageview');  // Send a pageview event when the component mounts
  }, []);

  return (
    <ThemeProvider theme={ saasClassicTheme }>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          {/*<Sticky top={ height - 70 } innerZ={ 9999 } activeClass="sticky-nav-active">
            <StickyButton />
          </Sticky>*/}
          <Banner />
          <Features />
          <PartnerSection />
          <ServiceSection />
          <Stats />
          <Testimonial />
          <Disclaimer />
          <Footer />
          <StickyButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default AiExpertsEdgeLandingPage;
