import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';
import { style } from 'styled-system';

export const Section = styled.section `
  background-color: #0B2472;
  position: relative;
`;

export const BannerContainer = styled.div `
width : 100%;
`

export const ContentWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content : center;
  min-height: 100vh;
  @media only screen and (max-width: 1440px) {
    min-height: 90vh;
  }
  @media only screen and (max-width: 1366px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 1024px) {
    min-height: 47vh;
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction : column;
  align-items: center;
  justify-content : center;
    min-height: 70vh;
  }
  @media only screen and (max-width: 360px) {
    display: flex;
  align-items: center;
  flex-direction : column;
  justify-content : center;
    min-height: 95vh;
  }
`;

export const TextContent = styled.div `
  max-width: 580px;
  @media only screen and (max-width: 1200px) {
    max-width: 377px;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px auto 0;
    text-align: center;
  }
  h2 {
    color: ${ themeGet( 'colors.white' ) };
    font-weight: 700;
    font-size: 62px;
    line-height: 1.13;
    letter-spacing: -2px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 45px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 34px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }
  p {
    color: ${ rgba( '#fff', 0.8 ) };
    font-weight: 500;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: -0.3px;
    max-width: 514px;
    margin-bottom: 45px;
  }
`;

export const ButtonGroup = styled.div `
  display: flex;
  align-items: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
  button {
    background-color: #E21E22;
    border-radius: 100px;
    min-height: 60px;
    padding: 0 30px;
    margin-right: 25px;
    margin-top: 25px;
    span {
      color: white;
    }
    @media only screen and (max-width: 480px) {
      margin-right: 0;
      margin-bottom: 20px;
      min-height: 50px;
    }
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.33;
    color: ${ rgba( '#fff', 0.6 ) };
    margin: 0;
  }
`;

export const Illustration = styled.figure `
  ${ '' /*position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);*/}
  margin : 0px;
  @media only screen and (max-width: 768px) {
width : '100px';
  }
  ${ '' /*img {
    max-width: 93%;
    margin-left: auto;
    @media only screen and (max-width: 1600px) {
      max-width: 75%;
    }
    @media only screen and (max-width: 1440px) {
      max-width: 73%;
    }
    @media only screen and (max-width: 1366px) {
      max-width: 62%;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 60%;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 54%;
    }
  }*/}
`;
export const MainContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: #eaeaea;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    padding: 0rem 0rem 1rem 1rem;
  }
`;

export const ImageContainer = styled.div `
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 700px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 0rem 6rem 11rem;
  width: 50%;

  @media (max-width: 700px) {
    padding: 1rem;
    width: 100%;
    align-items: flex-start;
  }
`;

export const SubHeading = styled.p `
  font-size: 20px;
  font-weight: 500;
  margin: 5px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 18px;
        width: 100%;
        text-align: justify;
  }
`;

export const MainHeading = styled.p `
  font-size: 27px;
  font-weight: 500;
  margin: 5px 0px 10px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 22px;
  }
`;