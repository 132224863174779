import React from 'react';
import Container from '../../common/components/UI/Container';
import { Image } from 'antd';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Section, { SectionHeading, Grid, Item } from './stats.style';

import { stats } from '../../common/data';

const Stats = () => {
  return (
    <Section id="how-to">
      <Container width="1400px">
        <SectionHeading>
          <Heading content="We Are Proud To Say..." />
        </SectionHeading>
        <Grid>
          { stats.map( ( howTo ) => (
            <Item key={ howTo.id }>
              <figure>
                <Image src={ howTo.icon } alt="icon" preview={ false } />
              </figure>
              <Heading as="h4" content={ howTo.title } />
              <Text content={ howTo.text } />
            </Item>
          ) ) }
        </Grid>
      </Container>
    </Section>
  );
};

export default Stats;
