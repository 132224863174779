import React from 'react';
import Container from '../../common/components/UI/Container';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Text from '../../common/components/Text';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  ButtonGroup,
  MainContainer,
  BannerContainer,
  ImageContainer,
  TextContainer,
  SubHeading,
  MainHeading,
} from './banner.style';
//import HeroImage from '../../common/assets/Hero Image.png';
import { useMedia } from 'react-use';
import BannerImage from '../../common/assets/HeroSection/HerSectionImage.png'
import HEadingImage from '../../common/assets/HeroSection/HeadingHeroSection.png'
import JaroLogoBlue from '../../common/assets/jaroLogoBlue.png'

const Banner = () => {
  const navigateToTest = () => {
    window.open( `https://skilltest.jaroeducation.com/jaroedu/survey/TIC0y2Ll7I8`, '_blank' );
  };

  const isWide = useMedia( '(max-width: 700px)' );


  return (
    <>
      {
        isWide ?
          <Section id="home">
            <MainContainer>

              <ImageContainer>
                <Image src={ BannerImage } width={ 400 } preview={ false } />
              </ImageContainer>

              <TextContainer>
                <Image src={ HEadingImage } preview={ false } width={ 300 } />
                <MainHeading>Find Your Skills Gaps!</MainHeading>
                <SubHeading>• Identify hidden weaknesses</SubHeading>
                <SubHeading>• Gain personalized career insights</SubHeading>
                <SubHeading>• Stay ahead of the competition.</SubHeading>
                <ButtonGroup>
                  <Button title="Get Started Now" onClick={ navigateToTest } />
                </ButtonGroup>
              </TextContainer>
            </MainContainer>
          </Section>

          :

          <Section id="home">
            <MainContainer>
              <TextContainer>
                <Image src={ JaroLogoBlue } preview={ false } width={ 250 } style={{marginBottom : '4rem'}}/>
                <Image src={ HEadingImage } preview={ false } width={ 500 } />
                <MainHeading>Find Your Skills Gaps!</MainHeading>
                <SubHeading>• Identify hidden weaknesses</SubHeading>
                <SubHeading>• Gain personalized career insights</SubHeading>
                <SubHeading>• Stay ahead of the competition.</SubHeading>
                <ButtonGroup>
                  <Button title="Get Started Now" onClick={ navigateToTest } />
                </ButtonGroup>
              </TextContainer>
              <ImageContainer>
                <Image src={ BannerImage } width={ 600 } preview={ false } />
              </ImageContainer>
            </MainContainer>
          </Section>

      }
    </>

  );
};

export default Banner;
