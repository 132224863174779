import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import jaroLogo from '../../common/assets/Jaroedulogo.png';
import strideLogo from '../../common/assets/Stride Logo.png';
import React from 'react';
import { FooterArea, CopyrightContainer } from './footer.style';
import { Image } from 'antd';

const Footer = () => {

  return (
    <FooterArea>
      <Container width="1400px">
        <Box className="logoBox">
          <a className="logo">
            <Image src={ jaroLogo } preview={ false } width={ 200 } />
            <Text as="span" content={ <>India's Most Trusted Online Higher <br /> Education and Upskilling Company</> } />
          </a>
        </Box>
        <Box className="social">
          <Text as="h2" content={ <>Contact Us</> } />
          <Text as="span" content={ <><br />+91-9819016678</> } />
          <Text as="span" content={ <><br />counselling@jaro.in</> } />
        </Box>
      </Container>
      <CopyrightContainer>
        <Text as="span" content={ <><br />Copyright © 2024 Jaro Education. All rights reserved. Analytics Powered by Stride Ahead</> } />
      </CopyrightContainer>
    </FooterArea>
  );
};

export default Footer;
