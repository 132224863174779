import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../common/components/Navbar/index';
import Drawer from '../../common/components/Drawer/index';
import Button from '../../common/components/Button/index';
import Box from '../../common/components/Box';
import HamburgMenu from '../../common/components/HamburgMenu';
import Container from '../../common/components/UI/Container';
import { DrawerContext } from '../../common/contexts/DrawerContext';
import Text from '../../common/components/Text';
//import { NavbarWrapper } from './navbar.style';
//import { MENU_ITEMS } from 'common/data/SaasClassic';
//import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

//import LogoImage from 'common/assets/image/saasClassic/logo-white.png';
//import LogoImageAlt from 'common/assets/image/saasClassic/logo.png';
import { MainStickyWrapper, MainHeading, MainCtaButton } from './stickybutton.style';

const StickyButton = () => {

  const navigateToTest = () => {
    window.open( `https://skilltest.jaroeducation.com/jaroedu/survey/TIC0y2Ll7I8`, '_blank' );
  };

  const [countdown, setCountdown] = useState( 10 * 60 * 60 + 49 * 60 + 41 ); // 10 hours, 49 minutes, 41 seconds

  useEffect( () => {
    const timer = setInterval( () => {
      setCountdown( ( prevCountdown ) => prevCountdown - 1 );
    }, 1000 );

    return () => clearInterval( timer );
  }, [] );

  const formatTime = ( timeInSeconds ) => {
    const hours = Math.floor( timeInSeconds / 3600 );
    const minutes = Math.floor( ( timeInSeconds % 3600 ) / 60 );
    const seconds = timeInSeconds % 60;

    return `${ hours }:${ minutes }:${ seconds }`;
  };

  return (
    <MainStickyWrapper>
      <MainHeading>
        Free <span style={ { textDecoration: 'line-through' } }>₹1499</span> (offer ends in { formatTime( countdown ) })
      </MainHeading>

      <MainCtaButton onClick={ navigateToTest }>
        Calculate Now!
      </MainCtaButton>
    </MainStickyWrapper>
  );
};

//StickyButton.propTypes = {
//  StickyButtonStyle: PropTypes.object,
//  logoStyle: PropTypes.object,
//  button: PropTypes.object,
//  row: PropTypes.object,
//  mainText: PropTypes.object,
//  containerStyle: PropTypes.object,
//  menuWrapper: PropTypes.object,
//};

//StickyButton.defaultProps = {
//  navbarStyle: {
//    minHeight: ['100px', '70px', '70px', '70px', '70px'],
//    backgroundColor: '#0B2472',
//  },
//  containerStyle: {
//    pr: '0',
//    pl: '0',
//  },
//  row: {
//    flexBox: true,
//    justifyContent: 'space-between',
//    display: 'flex',
//    alignItems: 'center',
//    width: '100%',
//    pl: ['16px', '30px', '100px', '200px', '290px'],
//    pr: ['16px', '30px', '100px', '200px', '290px'],
//  },
//  logoStyle: {
//    maxWidth: ['120px', '130px'],
//  },
//  button: {
//    type: 'button',
//    fontSize: ['12px', '10px', '16px', '16px', '16px'],
//    fontWeight: '700',
//    borderRadius: '4px',
//    pl: '15px',
//    pr: '15px',
//    pt: '0px',
//    pb: '0px',
//    mr: '25px',
//    ml: '25px',
//    backgroundColor: '#ffffff',
//    color: '#E31E23',
//  },
//  mainText: {
//    color: 'white',
//    fontSize: ['12px', '16px', '18px', '20px', '24px'],
//    fontWeight: '700',
//    mb: '0',
//    mr: '0',
//    lineHeight: '1.3',
//  },
//  menuWrapper: {
//    flexBox: true,
//    alignItems: 'center',
//  },
//};

export default StickyButton;
