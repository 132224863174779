import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const CopyrightContainer = styled.div `
display : flex;
justify-content: center;
margin-bottom : 5rem;
background : #ffffff;
span {
      color: #000000;
      font-weight: 400;
      font-size: 15px;
      text-align:center;
}

@media (max-width: 700px) {
  margin-bottom : 8rem;
  }

`
export const FooterArea = styled.footer `
  background : #0B2472;
  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #dfe4ed;
    border-bottom: 1px solid #dfe4ed;
    padding-top: 35px;
    padding-bottom: 50px;
    justify-content: space-between;
    @media (max-width: 1600px) {
      padding-bottom: 40px;
    }
    @media (max-width: 991px) {
      flex-direction: column;
    gap: 1rem;
    }
  }
  .logoBox {
    display: flex;
    flex-direction : column
    align-items: center;
    @media (max-width: 425px) {
      display: flex;
    flex-direction : column;
      justify-content: center;
      text-align: center;
    }
    p {
      margin: 0;
      color: #09131f;
      margin-left: 15px;
      margin-right: 5px;
      @media (max-width: 1600px) {
        font-size: 15px;
      }
    }
    span {
      color: #ffffff;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 15px;
      display: block;
      @media (max-width: 525px) {
        text-align : center;
        
      }
    }
  }
  .footerLink {
    line-height: 1;
    color: ${ themeGet( 'colors.primary' ) };
    font-size: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid currentColor;
  }
  .menu {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    a {
      font-size: 15px;
      line-height: 1;
      color: #0f2137;
      transition: all 500ms ease;
      &:hover {
        color: ${ themeGet( 'colors.primary' ) };
      }
      + a {
        margin-left: 35px;
      }
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    align-items: left;
    span {
      color: #ffffff;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 15px;
      display: block;
      @media (max-width: 525px) {
        text-align : center;
        
      }
    }
    h2 {
      color: #ffffff;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 0;
      font-size: 18px;
      display: block;
      @media (max-width: 525px) {
        text-align : center;
        
      }
    }
    a {
      margin-left: 15px;
      transition: opacity 0.4s ease;
      &:hover {
        opacity: .8;
      }
    }
    @media (max-width: 525px) {
        text-align : center;
      }
  }
`;