import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from '../../common/assets/sectionThree2x.png';

const PartnerSection = ( {
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
} ) => {
  return (
    <PartnerSectionWrapper>
      <Container width="1400px">
        <Box { ...row }>
          <Box { ...col } { ...textArea }>
            <Heading
              { ...title }
              content="See How Close You Are to Your Dream Career"
            />
            <Text
              { ...description }
              content="Take a look at the detailed report waiting for you. Find out where you excel and where you can grow."
            />
            <Box>
              <a href='https://stride-survey-assets.s3.amazonaws.com/jaro/sampleReport'>
                <Text { ...button } content="See Sample Report ➞" />
              </a>
            </Box>
          </Box>
          <Box { ...col } { ...imageArea }>
            <Image src={ Partner } alt="Domain Image" preview={ false } />
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: '18px',
    color: '#504E4E',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#E31E23',
  },
};

export default PartnerSection;
